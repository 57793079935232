import {
  MetaSwiper,
  SafariSwiper,
  swiperObserver,
  swiperOnResize,
  getPosition,
} from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";

import * as yup from "https://cdn.jsdelivr.net/npm/yup@1.2.0/+esm";

AOS.init({
  startEvent: "load",
  disableMutationObserver: false,
  duration: 600,
  once: true,
});
AOS.refresh(true);

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

// We listen to the resize event
window.addEventListener("resize", () => {
  // We execute the same script as before
  vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  document.documentElement.style.setProperty(
    "--header-height",
    `${
      document.getElementsByClassName("header")[0].getBoundingClientRect()
        .height
    }px`
  );
});

const header = document.getElementsByClassName(`header`)[0];

function handleScroll() {
  if (scrollContainer().scrollTop > 0) {
    header.classList.add("scrolled");
  } else {
    header.classList.remove("scrolled");
  }
}
function scrollContainer() {
  return document.documentElement || document.body;
}
function onDOMContentLoaded() {
  handleScroll();
  document.addEventListener("scroll", handleScroll);
}
document.addEventListener("DOMContentLoaded", onDOMContentLoaded);

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

$(".dropdown-toggle").click(function (e) {
  if (isMobileMenuEnable) {
    //close all opened sub menu
    $(".menu-item.dropdown.active .dropdown-menu").slideUp({
      complete: function () {
        $(this).closest(".dropdown").removeClass("active");
      },
    });

    //open current submenu
    $(this).closest(".menu-item.dropdown").toggleClass("active");
    if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
      e.preventDefault();
      $(this).next(".dropdown-menu").slideDown();
    }
  }
});

// toggle menu handler
function menuToggle() {
  $(".menu-toggle").toggleClass("active");
  $(".navbar-nav").toggleClass("active");
  $(".header-close-wrapper").toggleClass("active");
  // LockScroll when burger open and enable when closed and enable scroll on menu
  const scrollState = scrollLock.getScrollState();

  if (scrollState) {
    scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"));
    scrollLock.addScrollableSelector(".simplebar-content-wrapper");
  } else {
    scrollLock.enablePageScroll();
  }
}
//menu update function
function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".mobile-header").length;
  if (!isMobileMenuEnable) {
    $(".dropdown-menu").css("display", "");
    $(".header-close-wrapper").removeClass("active");
    $(".menu-item.active").removeClass("active");
    $(".navbar-nav").removeClass("active");
    $(".menu-toggle").removeClass("active");
    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
if (window.innerWidth <= mobileMenuStartPoint) {
  $(".close-menu-toggle").click(menuToggle); //menu toggles
}
$(".header-close-wrapper").click(menuToggle); //menu toggles

if (document.getElementsByClassName("decor-path")[0]) {
  const decors = document.querySelectorAll(".decor-path path");
  function getPathLength(figure) {
    figure.forEach((elem) => {
      elem.style.strokeDasharray = `${elem.getTotalLength()}`;
      elem.style.strokeDashoffset = `${elem.getTotalLength()}`;
    });
  }
  getPathLength(decors);
}

if (document.querySelector(".who-we-are-section__sector .line-decor")) {
  const childArr = document.querySelectorAll(
    ".who-we-are-section__sector .line-decor"
  );
  childArr.forEach((item, i) => {
    if (i >= 9) {
      item.style.transitionDelay = `1.0${i + 1}s`;
    } else if (i >= 19) {
      item.style.transitionDelay = `1.1${i + 1}s`;
    } else {
      item.style.transitionDelay = `0.${i + 1}s`;
    }
  });
}

const scrollEvents = () => {
  const trueScrollTarget = document.getElementsByClassName("scroll-target")[0];

  if (trueScrollTarget) {
    const scrollTarget = document.querySelectorAll(".scroll-target");
    let threshold = 0.5;
    let decorsArr = [];

    if (document.getElementsByClassName("scroll-decor")[0]) {
      decorsArr = document.querySelectorAll(".scroll-decor");
    }

    if ("IntersectionObserver" in window) {
      console.log("IntersectionObserver is supported");
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry, i) => {
            if (entry.isIntersecting) {
              if (decorsArr.length !== 0) {
                decorsArr.forEach((elem, i) => {
                  if (entry.target === elem) {
                    elem.classList.add("animation");
                  }
                });
              }
            }
          });
        },
        {
          threshold: 0,
          rootMargin: "0% 0% -10% 0%",
        }
      );

      scrollTarget.forEach((target, index) => {
        observer.observe(target);
      });
    } else {
      console.log("IntersectionObserver doesn't supported!");
    }
  }
};

scrollEvents();

function scrollToElem(el) {
  let $page = $("html, body"),
    speed = 200;

  el = el || "body";

  if (/^(.*?)\|\w{1,32}$/i.test(el)) {
    let arr = el.split("|");
    el = arr[0];
    speed = parseInt(arr[1]);
  }

  let targetScrollTop = $(el).offset().top - 110;

  $page.animate(
    {
      scrollTop: targetScrollTop,
    },
    speed
  );
}

/*function disableChoices() {
	const choicesDOM = document.querySelector(["[data-choices]"]);
	if (choicesDOM) {
		const choicesArr = document.querySelectorAll(["[data-choices]"]);
		for (let i = 0; i < choicesArr.length; i++) {
			const choices = new Choices(choicesArr[i], {
				searchEnabled: true,
				itemSelectText: "",
				placeholder: true,
			});

			console.log(choices);

			choices.destroy();
		}
	}
}*/

window.canvasMain = function canvasMain() {
  const canvasMain = document.getElementsByClassName("canvas-main-gradient")[0];

  if (canvasMain) {
    const canvasMainArr = document.querySelectorAll(".canvas-main-gradient");
    canvasMainArr.forEach((elem, i) => {
      if (!elem.classList.contains("is-start")) {
        elem.classList.add("is-start");
        /*elem.style.opacity = "1";*/

        const parentCard = elem.parentNode;
        const granimInstance = new Granim({
          element: elem,
          direction: "diagonal",
          isPausedWhenNotInView: false,
          states: {
            "default-state": {
              gradients: [
                ["#5bbe9d", "#2d9472", "#227c66", "#127059", "#095440"],
                ["#095440", "#127059", "#227c66", "#2d9472", "#5bbe9d"],
              ],
              transitionSpeed: 3000,

              loop: true,
            },
          },
        });

        granimInstance.pause();

        parentCard.addEventListener("mouseenter", () => {
          // granimInstance.changeState("default-state");
          granimInstance.play();
        });

        parentCard.addEventListener("mouseleave", () => {
          // granimInstance.changeState("start-state");
          granimInstance.pause();
        });
      }
    });
  }
};
canvasMain();

window.jobCardHeight = function jobCardHeight() {
  if (document.querySelector(".job-card")) {
    const jobCardsArr = document.querySelectorAll(".job-card");
    let maxHeight = 0;

    jobCardsArr.forEach((card, i) => {
      const cardHeight = card.offsetHeight;
      if (cardHeight > maxHeight) {
        maxHeight = cardHeight;
      }
    });

    jobCardsArr.forEach((card, i) => {
      card.style.minHeight = `${maxHeight}px`;
    });
  }
};

jobCardHeight();

function initChoices() {
  const choicesDOM = document.querySelector(["[data-choices]"]);
  if (choicesDOM) {
    const parentArr = document.querySelectorAll(".default-select__container");
    const choicesArr = document.querySelectorAll(["[data-choices]"]);
    for (let i = 0; i < choicesArr.length; i++) {
      let optionName = null;
      let optionValue = null;

      /*const parentContainer = choicesArr[i].parentNode.parentNode;*/
      const parentContainer = parentArr[i];
      const list = parentContainer.querySelector(".default-select__list");

      if (!choicesArr[i].parentNode.classList.contains(".choices__inner")) {
        let flippedStateClass = "is-flipped";

        if (choicesArr[i].classList.contains("not-flipped")) {
          flippedStateClass = "not-flipped";
        }

        const choices = new Choices(choicesArr[i], {
          searchEnabled: true,
          itemSelectText: "",
          placeholder: true,
          classNames: {
            flippedState: flippedStateClass,
          },
        });

        const multiplePlaceholder = parentContainer.querySelector(
          ".choices__list--multiple"
        );

        const choicesElement = parentContainer.querySelector(
          ".choices[data-type='select-multiple']"
        );

        function createListItem(optionName, optionValue) {
          const listItem = document.createElement("li");
          listItem.className = "default-select__list-item";
          listItem.setAttribute("data-val", optionValue);
          listItem.innerHTML = `
				<i class="icon-close"></i>
				<span>${optionName}</span>
			  `;
          list.appendChild(listItem);
          list.classList.add("active");

          listItem.addEventListener("click", function () {
            handleListItemClick(listItem);
          });
        }
        function handleSelectedOptions() {
          list.innerHTML = "";

          if (choicesArr[i].multiple) {
            const selectedOptions = Array.from(choicesArr[i].selectedOptions);

            if (selectedOptions.length === 0 && !choicesArr[i].multiple) {
              choices.setChoiceByValue("");
            }

            selectedOptions.forEach(function (option) {
              optionName = option.textContent;
              optionValue = option.value;
              if (optionName !== "Select") {
                createListItem(optionName, optionValue);
              }
            });

            const listArr = list.querySelectorAll(".default-select__list-item");
            if (listArr.length === 1) {
              multiplePlaceholder.textContent = optionName.replace(
                /&amp;/g,
                "&"
              );
            } else if (listArr.length >= 2) {
              multiplePlaceholder.textContent = `Selected ${listArr.length} items`;
            } else {
              multiplePlaceholder.textContent = "Select";
            }
          }
        }

        function handleListItemClick(listItem) {
          const optionValue = listItem.getAttribute("data-val");

          choices.removeActiveItemsByValue(optionValue);
          handleSelectedOptions();

          if (choicesArr[i].getAttribute("id") === "function") {
            load("profile/sub_functions", "function#function");
          }

          if (choicesArr[i].getAttribute("id") === "industries") {
            load("profile/sub_industries", "industries#industries");
          }
        }

        list.addEventListener("click", function (event) {
          const liElement = event.target.closest(".default-select__list-item");
          if (liElement) {
            handleListItemClick(liElement);
          }
        });

        handleSelectedOptions();

        choicesArr[i].addEventListener("change", function () {
          handleSelectedOptions();
        });

        if (choicesElement) {
          choicesElement.addEventListener("click", () => {
            if (parentContainer.querySelector(".is-open")) {
              choices.hideDropdown();
            }
          });

          choicesElement.addEventListener("change", () => {
            choices.hideDropdown();
          });
        }
      }
    }
  }
}
initChoices();

const localeEn = {
  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  daysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  monthsShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  today: "Today",
  clear: "Clear",
  dateFormat: "MM/dd/yyyy",
  timeFormat: "hh:mm aa",
  firstDay: 0,
};

function dateInput() {
  if (document.getElementsByClassName("date-input")[0]) {
    const dateInputsArr = document.querySelectorAll(".date-input");
    dateInputsArr.forEach((dateInput, i) => {
      if (!dateInput.classList.contains("is-init")) {
        dateInput.classList.add("is-init");
        const airDatepicker = new AirDatepicker(dateInput, {
          dateFormat: "dd/MM/yyyy",
          locale: localeEn,
          onSelect: function (formattedDate, date, inst) {
            dateInput.value = formattedDate.formattedDate;
            console.log(dateInput.value);
          },
        });

        document.addEventListener("scroll", () => {});

        document.addEventListener("wheel", (event) => {
          console.log("Wheel event triggered"); // Проверка, что событие вызывается
          const datepickerElement = document.querySelector(".air-datepicker");

          if (
            datepickerElement &&
            document.querySelector("#popup").classList.contains("active")
          ) {
            airDatepicker.hide();
          }
        });
      }
    });
  }
}
dateInput();

const portalNav = document.getElementsByClassName("portal-section__nav")[0];
let portalNavSwiper = null;
function initializeSwiper() {
  portalNavSwiper = new Swiper(".portal-section__nav", {
    slidesPerView: "auto",
    spaceBetween: 10,
    slideToClickedSlide: true,
  });
}
function destroySwiper() {
  if (portalNavSwiper !== null) {
    portalNavSwiper.destroy();
    portalNavSwiper = null;
  }
}
function handleWindowResize() {
  if (portalNav && window.innerWidth <= 1200) {
    if (portalNavSwiper === null) {
      initializeSwiper();
    }
  } else {
    destroySwiper();
  }
}

window.addEventListener("resize", handleWindowResize);
handleWindowResize();

if (document.querySelector(".benefits-slider")) {
  const benefitsSliderArr = document.querySelectorAll(".benefits-slider");
  benefitsSliderArr.forEach((benefitsSlider, idx) => {
    if (benefitsSlider) {
      const benefitsItems = benefitsSlider.querySelectorAll(
        ".benefits-slider__item"
      );
      const sliderButtons = document.querySelectorAll(
        ".benefits-slider-buttons"
      )[idx];

      if (window.innerWidth >= 901) {
        benefitsItems.forEach((item, i) => {
          item.addEventListener("click", () => {
            benefitsSlider
              .querySelector(".benefits-slider__item.active")
              .classList.remove("active");
            item.classList.add("active");
          });
        });
      }

      if (window.innerWidth <= 551) {
        const contentsArr = benefitsSlider.querySelectorAll(
          ".benefits-slider__item-content"
        );
        let maxHeight = 0;
        contentsArr.forEach((content) => {
          const contentHeight = content.clientHeight;
          if (contentHeight > maxHeight) {
            maxHeight = contentHeight;
          }
        });

        contentsArr.forEach((content, i) => {
          benefitsItems[i].style.minHeight = `${maxHeight + 48}px`;
        });
      }

      let allowTouchMove = true;

      if (benefitsItems.length <= 5 && window.innerWidth >= 902) {
        allowTouchMove = false;
        sliderButtons.style.display = "none";
      }

      if (benefitsItems.length === 5) {
        benefitsSlider.classList.add("min-type");
      }

      let delay = 3000;
      if (window.innerWidth <= 768) {
        delay = 8000;
      }

      const benefitsSwiper = new Swiper(benefitsSlider, {
        slidesPerView: 1,

        spaceBetween: 20,
        threshold: 10,
        allowTouchMove: allowTouchMove,
        slideToClickedSlide: true,
        autoplay: {
          delay: delay,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
          prevEl: sliderButtons.querySelector(".slider-btn.prev"),
          nextEl: sliderButtons.querySelector(".slider-btn.next"),
        },
        breakpoints: {
          902: {
            slidesPerView: "auto",
            spaceBetween: 20,
            autoplay: false,
            slideToClickedSlide: false,
          },

          1200: {
            slidesPerView: "auto",
            spaceBetween: benefitsItems.length === 5 ? 10 : 18,
            autoplay: false,
            slideToClickedSlide: false,
          },

          1350: {
            slidesPerView: "auto",
            spaceBetween: benefitsItems.length === 5 ? 20 : 40,
            autoplay: false,
            slideToClickedSlide: false,
          },
        },
      });
    }
  });
}

const methodologySlider =
  document.getElementsByClassName("methodology-slider")[0];

if (methodologySlider) {
  const managementItems = document.querySelectorAll(".swiper-slide");

  let delay = 3000;
  if (window.innerWidth <= 768) {
    delay = 8000;
  }

  const managementSwiper = new Swiper(".methodology-slider", {
    slidesPerView: 1,

    spaceBetween: 16,
    threshold: 10,
    autoplay: {
      delay: delay,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: ".methodology-slider-buttons .slider-btn.prev",
      nextEl: ".methodology-slider-buttons .slider-btn.next",
    },
    breakpoints: {
      552: {
        slidesPerView: 2,
        spaceBetween: 18,
      },

      852: {
        slidesPerView: 3,
        spaceBetween: 20,
      },

      1100: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
  });

  swiperObserver(managementSwiper);
}

const keySlider = document.getElementsByClassName("key-slider")[0];

if (keySlider) {
  const keyItems = document.querySelectorAll(".swiper-slide");
  const keyButtons = document.querySelector(".key-slider-buttons");

  let delay = 3000;
  if (window.innerWidth <= 768) {
    delay = 4000;
  }

  if (keyItems.length <= 4 && window.innerWidth <= 1025) {
    keyButtons.style.display = "none";
  } else {
    startSlider();
    keyButtons.style.display = "flex";
  }

  function startSlider() {
    const keySwiper = new Swiper(".key-slider", {
      slidesPerView: 1,

      spaceBetween: 16,
      threshold: 10,
      autoplay: {
        delay: delay,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".key-slider-buttons .slider-btn.prev",
        nextEl: ".key-slider-buttons .slider-btn.next",
      },
      breakpoints: {
        374: {
          slidesPerView: 2,
          spaceBetween: 18,
        },

        552: {
          slidesPerView: 3,
          spaceBetween: 18,
        },

        852: {
          slidesPerView: 4,
          spaceBetween: 20,
        },

        1025: {
          slidesPerView: 5,
          spaceBetween: 16,
        },

        1100: {
          slidesPerView: 5,
          spaceBetween: 20,
        },

        1280: {
          slidesPerView: 5,
          spaceBetween: 33,
        },
      },
    });

    swiperObserver(keySwiper);
  }
}

const becomeSlider = document.getElementsByClassName("become-slider")[0];

if (becomeSlider) {
  const becomeItems = document.querySelectorAll(".swiper-slide");

  let delay = 3000;
  if (window.innerWidth <= 768) {
    delay = 8000;
  }

  const becomeSwiper = new Swiper(".become-slider", {
    slidesPerView: 1,

    spaceBetween: 16,
    threshold: 10,
    autoplay: {
      delay: delay,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: ".become-slider-buttons .slider-btn.prev",
      nextEl: ".become-slider-buttons .slider-btn.next",
    },
    breakpoints: {
      552: {
        slidesPerView: 2,
      },

      852: {
        slidesPerView: 3,
      },

      1100: {
        slidesPerView: 4,
      },
    },
  });

  swiperObserver(becomeSwiper);
}

const whyChooseSlider = document.getElementsByClassName("why-choose-slider")[0];

if (whyChooseSlider) {
  const whyChooseItems = document.querySelectorAll(".swiper-slide");

  let delay = 3000;
  if (window.innerWidth <= 768) {
    delay = 8000;
  }

  const whyChooseSwiper = new Swiper(".why-choose-slider", {
    slidesPerView: 1,

    spaceBetween: 16,
    threshold: 10,
    autoplay: {
      delay: delay,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: ".why-choose-slider-buttons .slider-btn.prev",
      nextEl: ".why-choose-slider-buttons .slider-btn.next",
    },
    breakpoints: {
      552: {
        slidesPerView: 2,
        spaceBetween: 20,
      },

      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },

      1200: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
  });

  swiperObserver(whyChooseSwiper);
}

const whatSlider = document.getElementsByClassName("what-slider")[0];

if (whatSlider) {
  const whatItems = document.querySelectorAll(".swiper-slide");

  let delay = 3000;
  if (window.innerWidth <= 768) {
    delay = 8000;
  }

  const whatSwiper = new Swiper(".what-slider", {
    slidesPerView: 1,

    spaceBetween: 16,
    threshold: 10,
    autoplay: {
      delay: delay,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: ".what-slider-buttons .slider-btn.prev",
      nextEl: ".what-slider-buttons .slider-btn.next",
    },
    breakpoints: {
      552: {
        slidesPerView: 2,
        spaceBetween: 20,
      },

      1024: {
        slidesPerView: 3,
        spaceBetween: 16,
      },

      1200: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  });

  swiperObserver(whatSwiper);
}

const whySlider = document.getElementsByClassName("why-slider")[0];

if (whySlider) {
  const whyItems = document.querySelectorAll(".swiper-slide");

  let delay = 3000;
  if (window.innerWidth <= 768) {
    delay = 8000;
  }

  const whySwiper = new Swiper(".why-slider", {
    slidesPerView: 1,

    spaceBetween: 16,
    threshold: 10,
    autoplay: {
      delay: delay,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: ".why-slider-buttons .slider-btn.prev",
      nextEl: ".why-slider-buttons .slider-btn.next",
    },
    breakpoints: {
      552: {
        slidesPerView: 1.5,
        spaceBetween: 20,
      },

      1024: {
        slidesPerView: 2,
        spaceBetween: 16,
      },

      1200: {
        slidesPerView: 2.3,
        spaceBetween: 20,
      },
    },
  });

  swiperObserver(whySwiper);
}

let areasSwiperWr = document.querySelectorAll(".areas-swiper-wr");
areasSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector(".swiper");
    let nextEl = el.querySelector(".slider-btn .next");
    let prevEl = el.querySelector(".slider-btn .prev");

    swiperOnResize("(max-width: 1023px)", swiperEl, {
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 800,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        500: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });
  }
});

const recentNewsSlider =
  document.getElementsByClassName("recent-news-slider")[0];

if (recentNewsSlider) {
  const recentNewsItems = document.querySelectorAll(".swiper-slide");

  let delay = 3000;
  if (window.innerWidth <= 768) {
    delay = 8000;
  }

  const whyChooseSwiper = new Swiper(".recent-news-slider", {
    slidesPerView: 1,

    spaceBetween: 16,
    threshold: 10,
    autoplay: {
      delay: delay,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: ".recent-news-slider-buttons .slider-btn.prev",
      nextEl: ".recent-news-slider-buttons .slider-btn.next",
    },
    breakpoints: {
      552: {
        slidesPerView: 2,
        spaceBetween: 20,
      },

      952: {
        slidesPerView: 3,
        spaceBetween: 20,
      },

      1200: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
  });

  swiperObserver(whyChooseSwiper);
}

const recentJobsSlider =
  document.getElementsByClassName("recent-jobs-slider")[0];

if (recentJobsSlider) {
  const recentJobsItems = document.querySelectorAll(".swiper-slide");

  let delay = 3000;
  if (window.innerWidth <= 768) {
    delay = 8000;
  }

  const recentJobsSwiper = new Swiper(".recent-jobs-slider", {
    slidesPerView: 1,

    spaceBetween: 16,
    threshold: 10,
    autoplay: {
      delay: delay,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: ".recent-jobs-slider-buttons .slider-btn.prev",
      nextEl: ".recent-jobs-slider-buttons .slider-btn.next",
    },
    breakpoints: {
      552: {
        slidesPerView: 2,
        spaceBetween: 20,
      },

      952: {
        slidesPerView: 3,
        spaceBetween: 16,
      },

      1200: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  });

  swiperObserver(recentJobsSwiper);
}

const processSlider = document.getElementsByClassName("process-slider")[0];
if (processSlider) {
  let processSwiper;

  let delay = 3000;

  function initializeValuesSwiper() {
    if (window.innerWidth <= 768) {
      delay = 8000;
    }

    processSwiper = new Swiper(".process-slider", {
      slidesPerView: 1,
      spaceBetween: 16,
      threshold: 10,
      autoplay: {
        delay: delay,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".process-slider-buttons .slider-btn.prev",
        nextEl: ".process-slider-buttons .slider-btn.next",
      },
      breakpoints: {
        521: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        652: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
      },
    });
  }

  function destroyValuesSwiper() {
    if (processSwiper) {
      processSwiper.destroy();
      processSwiper = undefined;
    }
  }

  function handleWindowResize() {
    if (window.innerWidth <= 901) {
      if (!processSwiper) {
        initializeValuesSwiper();
      }
    } else {
      destroyValuesSwiper();
    }
  }

  handleWindowResize();
  window.addEventListener("resize", handleWindowResize);
}

if (document.getElementsByClassName("our-areas-section__item")[0]) {
  const ourAreasItemsArr = document.querySelectorAll(
    ".our-areas-section__item"
  );
  ourAreasItemsArr.forEach((item, j) => {
    const listItems = item.querySelectorAll("ul li");
    const btn = item.querySelector(".our-areas-section__item-btn");

    item.parentNode.lastElementChild.classList.add("last-item");

    if (item.classList.contains("last-item")) {
      if (listItems.length <= 5) {
        btn.classList.add("hidden");
      }
    } else {
      if (listItems.length <= 6) {
        btn.classList.add("hidden");
      }
    }

    listItems.forEach((listItem, i) => {
      console.log(item[2]);
      if (item.classList.contains("last-item")) {
        if (i > 4) {
          listItem.style.display = "none";
        }
      } else {
        if (i > 5) {
          listItem.style.display = "none";
        }
      }
    });

    btn.addEventListener("click", () => {
      if (item.classList.contains("active")) {
        item.classList.remove("active");
        btn.textContent = "+ More";
      } else {
        item.classList.add("active");
        btn.textContent = "- Close";
      }
    });
  });
}

function accordionFunc(elem) {
  const itemsArr = elem.querySelectorAll(".accordion__item");
  itemsArr.forEach((item, i) => {
    item.addEventListener("click", () => {
      for (let j = 0; j < itemsArr.length; j++) {
        if (itemsArr[j].classList.contains("active")) {
          itemsArr[j].classList.remove("active");
        }
      }

      item.classList.add("active");
    });
  });
}

const useCasesAccordion = document.querySelector(".accordion.use-cases-type");
if (useCasesAccordion) {
  accordionFunc(useCasesAccordion);
}

/*const faqAccordion = document.querySelector(".accordion.faq-type");
if (faqAccordion) {
  accordionFunc(faqAccordion);
}*/

const faqAccordionsContainer = document.getElementsByClassName(
  "faq-section__accordions"
)[0];
if (faqAccordionsContainer) {
  const accordionsButtons = document.querySelectorAll(
    ".faq-section__accordion-btn"
  );
  const accordions = faqAccordionsContainer.querySelectorAll(
    ".faq-section__accordion"
  );

  accordions.forEach((accordion) => {
    accordionFunc(accordion);
  });

  accordionsButtons.forEach((btn, i) => {
    btn.addEventListener("click", () => {
      accordions.forEach((accordion) => {
        accordion.classList.remove("active");
      });
      for (let j = 0; j < accordionsButtons.length; j++) {
        accordionsButtons[j].classList.remove("active");
      }

      btn.classList.add("active");
      accordions[i].classList.add("active");
    });
  });
}

function initializeFormValidation() {
  const portalForm = document.querySelector(
    ".portal-form:not(.not-yup-validation)"
  );
  if (portalForm) {
    /*linkedin: yup
				.string()
				.matches(
					/^(https?:\/\/)?([0-9a-z][0-9a-z\-]+\.[0-9a-z]+)+(.*?)$/i,
					"Please enter a valid LinkedIn profile URL"
				)
				.required("Please enter a LinkedIn profile URL"),*/
    // Create the validation schema using Yup
    const schema = yup.object().shape({
      name: yup.string().required("Please enter your name"),
      first_name: yup.string().required("Please enter your First Name"),
      last_name: yup.string().required("Please enter your Last Name"),
      date_birth: yup.string().required("Please select your Date of birth"),
      when_start: yup
        .string()
        .required("Please enter when and are you ready to start?"),
      email: yup
        .string()
        .matches(
          /^([a-z0-9_\.\-]{1,100})@([a-z0-9\.\-]{1,80})\.([a-z\.]{2,16})+$/i,
          "Please enter a valid email"
        )
        .required("Please enter your email"),
      phone: yup
        .string()
        .matches(
          /^[\d\s()+-]+$/,
          "Please enter only digits for the phone number"
        )
        .required("Please enter a phone number"),
      password: yup
        .string()
        .matches(
          /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,32}$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one digit, and be 8-32 characters long"
        )
        .required("Please enter a password"),
      confirm_password: yup
        .string()
        .test("password", "Passwords must match", () => {
          const confirmPasswordElem = portalForm.querySelector(
            'input[name="confirm_password"]'
          );
          const passwordElem = portalForm.querySelector(
            'input[name="password"]'
          );
          return confirmPasswordElem.value === passwordElem.value;
        })
        .required("Please enter a confirm password"),
      current_password: yup
        .string()
        .matches(
          /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,32}$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one digit, and be 8-32 characters long"
        )
        .required("Please enter a current password"),
      create_password: yup
        .string()
        .matches(
          /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,32}$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one digit, and be 8-32 characters long"
        )
        .test("password", "Passwords must match", () => {
          const oldPasswordElem = portalForm.querySelector(
            'input[name="current_password"]'
          );
          const passwordElem = portalForm.querySelector(
            'input[name="create_password"]'
          );
          return oldPasswordElem.value !== passwordElem.value;
        })
        .required("Please enter a new password"),
      confirm_new_password: yup
        .string()
        .test("password", "Passwords must match", () => {
          const confirmPasswordElem = portalForm.querySelector(
            'input[name="confirm_new_password"]'
          );
          const passwordElem = portalForm.querySelector(
            'input[name="create_password"]'
          );
          return confirmPasswordElem.value === passwordElem.value;
        })
        .required("Please enter a confirm password"),
      city: yup.string().required("Please enter your city"),
      bill_rate: yup
        .string()
        .matches(/^[0-9]+$/, "Please enter only numbers for the bill rate")
        .required("Please enter the bill rate"),
      message: yup
        .string()
        .min(10, "Your message must be 10 characters or longer")
        .required("Please enter a message"),
      check: yup
        .boolean()
        .oneOf([true], "Please accept the policy")
        .test("isChecked", "Please accept the policy", function (value) {
          return value === true || value === false;
        }),
    });

    // Create an object to track the validation state of fields
    const isFieldValid = {
      name: false,
      first_name: false,
      last_name: false,
      date_birth: false,
      when_start: false,
      email: false,
      phone: false,
      password: false,
      confirm_password: false,
      current_password: false,
      create_password: false,
      confirm_new_password: false,
      city: false,
      bill_rate: false,
      message: false,
      check: false,
    };

    portalForm.addEventListener("input", function (event) {
      const input = event.target;
      const fieldName = input.name;

      // Check if the input field exists in the form
      const inputFieldExists = portalForm.querySelector(
        `[name="${fieldName}"]`
      );
      if (!inputFieldExists) {
        return; // Skip validation if the input field doesn't exist in the form
      }

      // Проверьте валидность поля с помощью Yup
      yup
        .reach(schema, fieldName)
        .validate(input.value)
        .then(function () {
          // Поле прошло валидацию
          isFieldValid[fieldName] = true;
          clearValidationError(fieldName);
        })
        .catch(function (error) {
          // Поле не прошло валидацию
          isFieldValid[fieldName] = false;
          displayValidationError(fieldName, error.message);
        });
    });

    // Найти кнопку отправки формы
    const submitButton = portalForm.querySelector(
      ".portal-form button.btn-submit"
    );

    // Добавить обработчик события клика на кнопку
    submitButton.addEventListener("click", function (event) {
      event.preventDefault(); // Предотвратить отправку формы

      // Получить форму и данные
      const portalForm = document.querySelector(".portal-form");
      const formData = new FormData(portalForm);
      const data = Object.fromEntries(formData.entries());

      // Проверить данные с использованием Yup
      schema
        .validate(data, { abortEarly: false })
        .then(function () {
          // Данные прошли валидацию, выполнить действия по отправке формы

          /*portalForm.submit(); // Отправить форму*/
          pastPositionsFormVisible();
        })
        .catch(function (errors) {
          const fieldNamesInErrors = errors.inner.map((error) => error.path);
          checkRequiredSelects();

          // Получить все видимые поля формы из разметки
          const formFields = Array.from(portalForm.elements).filter((field) => {
            return (
              field.type !== "button" &&
              field.type !== "hidden" &&
              field.offsetWidth > 0
            );
          });

          // Создать массив с именами всех видимых полей формы
          const formFieldNames = formFields
            .filter((field) => field.name !== "") // Отфильтровать поля с непустым именем
            .map((field) => field.name); // Получить массив имен полей

          // Проверить наличие полей с ошибками в форме
          const areErrorFieldsInForm = fieldNamesInErrors.some((fieldName) =>
            formFieldNames.includes(fieldName)
          );

          let selectCheck = false;
          const selects = portalForm.querySelectorAll("select.required-select");
          for (const select of selects) {
            if (select.value === "") {
              selectCheck = true;
              break;
            }
          }

          // Если нет полей с ошибками в форме или форма не содержит полей вообще
          if (fieldNamesInErrors.length === 0 || !areErrorFieldsInForm) {
            // Выполнить действия по отправке формы
            /*portalForm.submit();*/
            const firstErrorInput = document.querySelectorAll(
              ".portal-form .error"
            );
            if (firstErrorInput.length) {
              scrollToElem(firstErrorInput[0]);
            }

            pastPositionsFormVisible();
          } else {
            submitButton.classList.add("error");
            setTimeout(function () {
              submitButton.classList.remove("error");
            }, 800);
            // Один или несколько полей не прошли валидацию
            /*console.log("Validation errors:", errors);*/
            // Отобразить сообщения об ошибках
            clearValidationErrors();
            if (errors.inner) {
              errors.inner.forEach(function (error) {
                const fieldName = error.path;
                const errorMessage = error.message;
                /*console.log(fieldName, errorMessage);*/
                displayValidationError(fieldName, errorMessage);
              });

              // Scroll to the first input element with an error
              const firstErrorInput = document.querySelectorAll(
                ".portal-form .error"
              );

              console.log(firstErrorInput.length);
              if (firstErrorInput.length) {
                scrollToElem(firstErrorInput[0]);
              }
            }
          }
        });
    });

    function pastPositionsFormVisible() {
      const pastPositionsForm = document.getElementsByClassName(
        "past-positions-form"
      )[0];
      if (pastPositionsForm) {
        portalForm.classList.add("hidden");
        pastPositionsForm.classList.remove("hidden");
      }
    }

    // Функция для отображения сообщения об ошибке валидации для указанного поля
    function displayValidationError(fieldName, errorMessage) {
      const inputElement = portalForm.querySelector(`[name="${fieldName}"]`);
      if (!inputElement) {
        return; // Skip if the input field doesn't exist in the form
      }
      const errorElement = document.getElementById(`${fieldName}-error`);
      inputElement.classList.add("error");
      if (errorElement) {
        errorElement.textContent = errorMessage;
      } else {
        const newErrorElement = document.createElement("div");
        newErrorElement.className = "error-message";
        newErrorElement.textContent = errorMessage;
        newErrorElement.id = `${fieldName}-error`;
        inputElement.parentNode.appendChild(newErrorElement);
      }
    }

    // Функция для очистки сообщения об ошибке валидации для указанного поля
    function clearValidationError(fieldName) {
      const inputElement = portalForm.querySelector(`[name="${fieldName}"]`);
      if (!inputElement) {
        return; // Skip if the input field doesn't exist in the form
      }
      const errorElement = document.getElementById(`${fieldName}-error`);
      inputElement.classList.remove("error");
      inputElement.classList.add("success");
      setTimeout(function () {
        inputElement.classList.remove("success");
      }, 500);
      if (errorElement) {
        errorElement.remove();
      }
    }

    // Функция для очистки всех сообщений об ошибках валидации
    function clearValidationErrors() {
      if (portalForm.querySelector(".error-message")) {
        const errorMessages = portalForm.querySelectorAll(".error-message");

        errorMessages.forEach(function (errorMessage) {
          errorMessage.remove();
        });
        if (portalForm.querySelector("input.error")) {
          const inputElements = portalForm.querySelectorAll("input.error");
          inputElements.forEach((input) => {
            input.classList.remove("error");
            input.classList.add("success");
            setTimeout(function () {
              input.classList.remove("success");
            }, 500);
          });
        }
      }
    }

    function checkRequiredSelects() {
      const selects = portalForm.querySelectorAll("select.required-select");
      let hasSelection = false;

      selects.forEach((select) => {
        if (select.value === "") {
          const parent = select.parentNode.parentNode.parentNode;
          const infoElement = parent.querySelector(
            ".default-select__info-error"
          );
          parent.classList.add("error");
          if (infoElement) {
            infoElement.textContent = "Please make a choice";
            infoElement.classList.add("active");
          }

          select.addEventListener("change", () => {
            if (select.value !== "") {
              parent.classList.remove("error");
              if (infoElement) {
                infoElement.textContent = "";
                infoElement.classList.remove("active");
              }
            }
          });
        }
      });
    }
  }
}
initializeFormValidation();

if (document.getElementsByClassName("password-input"[0])) {
  const passwordInputArr = document.querySelectorAll(".password-input");
  for (let i = 0; i < passwordInputArr.length; i++) {
    const input = passwordInputArr[i].querySelector("input");
    const btn = passwordInputArr[i].querySelector(".show-btn");

    btn.addEventListener("click", () => {
      if (input.type === "password") {
        input.type = "text";
        btn.classList.add("active");
      } else {
        input.type = "password";
        btn.classList.remove("active");
      }
    });
  }
}

const portalItemsContainer = document.getElementsByClassName(
  "portal-form__items-container"
)[0];

if (portalItemsContainer) {
  const cloneButton = document.getElementsByClassName("add-another-btn")[0];
  let count = 1;
  const newCountArray = [1];

  function findNextMinimum(arr) {
    const sortedArr = [...arr].sort((a, b) => a - b);

    for (let i = 0; i < sortedArr.length; i++) {
      if (sortedArr[i] !== i + 1) {
        return i + 1;
      }
    }

    return sortedArr.length > 0 ? sortedArr[sortedArr.length - 1] + 1 : 1;
  }

  function addNewIndexTitle() {
    const titlesArr = portalItemsContainer.querySelectorAll(
      ".portal-form__item-title"
    );
    titlesArr.forEach((title, i) => {
      title.textContent = `Experience #${i + 1}`;
    });
  }

  if (cloneButton) {
    cloneButton.addEventListener("click", () => {
      const clonedItem = portalItemsContainer.firstElementChild.cloneNode(true);
      portalItemsContainer.appendChild(clonedItem);

      /*let newCount = count + 1;*/
      let newCount = findNextMinimum(newCountArray);
      clonedItem.dataset.newCount = newCount;

      console.log(newCount);
      newCountArray.push(newCount);
      console.log(newCountArray);

      const clonedLabelJobTitle = clonedItem.querySelector(
        `label[for*='job-title-']`
      );
      const clonedInputJobTitle = clonedItem.querySelector(
        `input[id*='job-title-']`
      );
      const clonedLabelCompany = clonedItem.querySelector(
        `label[for*='company-']`
      );
      const clonedInputCompany = clonedItem.querySelector(
        `input[id*='company-']`
      );
      const clonedLabelStartDate = clonedItem.querySelector(
        `label[for*='start-date-']`
      );
      const clonedInputStartDate = clonedItem.querySelector(
        `input[id*='start-date-']`
      );
      const clonedLabelEndDate = clonedItem.querySelector(
        `label[for*='end-date-']`
      );
      const clonedInputEndDate = clonedItem.querySelector(
        `input[id*='end-date-']`
      );
      const clonedCloseBtn = clonedItem.querySelector(
        ".portal-form__item-close"
      );

      function setNewIdAndNames() {
        clonedLabelJobTitle.setAttribute("for", `job-title-${newCount}`);
        clonedInputJobTitle.setAttribute("id", `job-title-${newCount}`);
        clonedLabelCompany.setAttribute("for", `company-${newCount}`);
        clonedInputCompany.setAttribute("id", `company-${newCount}`);
        clonedLabelStartDate.setAttribute("for", `start-date-${newCount}`);
        clonedInputStartDate.setAttribute("id", `start-date-${newCount}`);
        clonedLabelEndDate.setAttribute("for", `end-date-${newCount}`);
        clonedInputEndDate.setAttribute("id", `end-date-${newCount}`);
      }
      setNewIdAndNames();

      clonedLabelJobTitle.textContent = clonedLabelJobTitle.textContent.replace(
        "*",
        ""
      );
      clonedInputJobTitle.value = "";
      clonedLabelCompany.textContent = clonedLabelCompany.textContent.replace(
        "*",
        ""
      );
      clonedInputCompany.value = "";
      clonedLabelStartDate.textContent =
        clonedLabelStartDate.textContent.replace("*", "");
      clonedInputStartDate.value = "";
      clonedLabelEndDate.textContent = clonedLabelEndDate.textContent.replace(
        "*",
        ""
      );
      clonedInputEndDate.value = "";
      clonedCloseBtn.classList.add("active");

      count++;
      newCount++;
      dateInput();
      addNewIndexTitle();

      clonedCloseBtn.addEventListener("click", () => {
        /*clonedItem.remove();
				const index = newCountArray.indexOf(newCount);

				newCountArray.splice(index, 1);*/

        const removedNewCount = Number(clonedItem.dataset.newCount);
        clonedItem.remove();

        const index = newCountArray.indexOf(removedNewCount);
        console.log(index);
        if (index !== -1) {
          newCountArray.splice(index, 1);
        }
        console.log(newCountArray);
        addNewIndexTitle();
      });
    });
  }

  function removeItemsWithSaves() {
    const portalItems =
      portalItemsContainer.querySelectorAll(".portal-form__item");

    portalItems.forEach((item, i) => {
      const closeBtn = item.querySelector(".portal-form__item-close");
      closeBtn.addEventListener("click", () => {
        item.remove();
        addNewIndexTitle();
      });
    });
  }
  removeItemsWithSaves();
}

window.validateCloneInputs = function validateCloneInputs() {
  const portalItemsContainer = document.getElementsByClassName(
    "portal-form__items-container"
  )[0];
  if (portalItemsContainer) {
    const inputs = portalItemsContainer.querySelectorAll("input");

    inputs.forEach((input) => {
      if (input.value.trim() === "") {
        input.classList.add("error");
      } else {
        input.classList.remove("error");
      }
    });
  }
};

/*const jsonData = '[ "job_title_1", "company_1"]';*/
window.validateInputs = function validateInputs(jsonData) {
  try {
    const data = JSON.parse(jsonData);
    if (!Array.isArray(data)) {
      throw new Error(
        "Invalid input format. Expected an array of field names."
      );
    }

    const errors = {};
    data.forEach((fieldName) => {
      const inputField = document.getElementsByName(fieldName)[0];
      const fieldValue = inputField.value.trim();
      console.log(fieldValue);
      if (fieldValue === "") {
        errors[fieldName] = "Required field.";
        inputField.classList.add("error");
      } else {
        inputField.classList.remove("error");
      }
    });

    return errors;
  } catch (error) {
    console.error("Error parsing JSON data:", error.message);
    return {};
  }
};

window.updateLogics = function updateLogics() {
  initChoices();
  dateInput();
};
/*validateInputs(jsonData);*/

const sidebarArrowButton = document.querySelector(".sidebar__item-arrow-btn");
if (sidebarArrowButton) {
  const sidebarArrowButtonsArr = document.querySelectorAll(
    ".sidebar__item-arrow-btn"
  );
  sidebarArrowButtonsArr.forEach((btn, i) => {
    const item = btn.parentElement.parentElement;
    const toggle = btn.parentElement;

    toggle.addEventListener("click", () => {
      btn.classList.toggle("active");
      item.classList.toggle("active");
    });
  });
}

const doubleRange = document.getElementsByClassName("double-range-tooltips")[0];
if (doubleRange) {
  const slider = doubleRange.querySelector("#double-range-tooltips");
  const max = +slider.dataset.max;
  const min = +slider.dataset.min;
  const unit = slider.dataset?.unit || "£";
  const step = +slider.dataset.step;
  const inputsHidden = doubleRange.querySelectorAll(
    ".double-range-hidden-input"
  );
  const startValueMin = +inputsHidden[0].value;
  const startValueMax = +inputsHidden[1].value;

  // how many percentages limit from borders ???? is 8%
  const borderLimit = 8;

  // each step is go backward for this amount of % ???? is 5%
  const borderDiff = 40 / borderLimit;

  noUiSlider.create(slider, {
    start: [startValueMin, startValueMax],
    connect: true,
    tooltips: true,
    margin: 10,
    step: step,
    range: {
      min: min,
      max: max,
    },
  });

  const tooltipsArr = slider.querySelectorAll(".noUi-tooltip");
  const circlesArr = slider.querySelectorAll(".noUi-origin");

  function returnTransform(element) {
    return element
      .replace(/[^0-9][^\d.]/g, "")
      .replace(")", "")
      .split(" ")
      .map((str) => {
        return Number(str);
      });
  }

  function needToMerge() {
    let tooltipOnePosition = tooltipsArr[0].getBoundingClientRect();
    let tooltipTwoPosition = tooltipsArr[1].getBoundingClientRect();

    if (
      tooltipsArr[0].classList.contains("hidden") ||
      tooltipsArr[1].classList.contains("hidden")
    ) {
      return true;
    }

    return (
      tooltipOnePosition.left +
        tooltipOnePosition.width -
        tooltipTwoPosition.left >
      0
    );
  }

  function resetTooltips(values) {
    mergeDiff = null;
    tooltipsArr.forEach((elem, index) => {
      elem.textContent =
        unit + Math.round(values[index]).toLocaleString("en-GB");
      elem.classList.remove("hidden");
    });
  }

  let trueTooltip = false;
  let mergeDiff = null;

  slider.noUiSlider.on("update", function (values, handle) {
    // translate of noUISlider -> 0% is start, 100% is end
    let translate = returnTransform(circlesArr[handle].style.transform)[0];

    // min value of double range slider
    let valueMin = returnTransform(circlesArr[0].style.transform)[0];

    // max value of double range slider
    let valueMax = returnTransform(circlesArr[1].style.transform)[0];

    // difference between min and max value of double range slider
    let difference = valueMax - valueMin;

    inputsHidden[handle].value = Math.round(values[handle]);

    // if tooltips are close to each other
    if (needToMerge()) {
      if (
        !tooltipsArr[+!handle].classList.contains("hidden") &&
        !tooltipsArr[handle].classList.contains("hidden")
      ) {
        trueTooltip = handle;
        mergeDiff = difference;
        tooltipsArr[+!handle].classList.add("hidden");
      }

      if (trueTooltip) {
        // limit left merged tooltip from overflowing
        // borderLimit * 3 === need for 3 times faster limitation because of merged tooltip
        if (translate <= -100 + borderLimit * 3) {
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 -
            difference +
            (Math.abs(translate + 100 - borderLimit * 3) * borderDiff) / 2.5
          }%,100%)`;
        } else {
          // position of tooltip in the middle of range
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 - difference
          }%,100%)`;
        }
      } else {
        // if left tooltip is grabbed
        if (translate >= -borderLimit * 4) {
          // limit right merged tooltip from overflowing
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 +
            difference -
            (Math.abs(translate + borderLimit * 3) * borderDiff) / 2.5
          }%,100%)`;
        } else {
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 + difference
          }%,100%)`;
        }
      }

      tooltipsArr[trueTooltip].textContent = `${
        unit + Math.round(values[0]).toLocaleString("en-GB")
      } - ${unit + Math.round(values[1]).toLocaleString("en-GB")}`;

      if (mergeDiff - difference < 0) {
        mergeDiff = null;
        resetTooltips(values);
      }
    } else {
      // limit left solo tooltip from overflowing
      if (translate <= -100 + borderLimit) {
        tooltipsArr[0].style.transform = `translate(${
          -50 + Math.abs(translate + 100 - borderLimit) * borderDiff
        }%,100%)`;
      } else if (translate >= -borderLimit) {
        // limit right solo tooltip from overflowing
        tooltipsArr[1].style.transform = `translate(${
          -50 - Math.abs(translate + borderLimit) * borderDiff
        }%,100%)`;
      } else {
        // position of tooltip in the middle of range
        tooltipsArr[handle].style.transform = "translate(-50%,100%)";
      }

      tooltipsArr[handle].textContent = `${
        unit + Math.round(values[handle]).toLocaleString("en-GB")
      }`;
    }
  });
}

const singleRangeSlider = () => {
  const singleRange = document.getElementsByClassName(
    "single-range-default"
  )[0];
  if (singleRange) {
    const slider = singleRange.querySelector("#single-range-default");
    const max = +slider.dataset.max;
    const min = +slider.dataset.min;
    const unit = slider.dataset?.unit || "£";
    const step = +slider.dataset.step;
    const inputsHidden = singleRange.querySelector(
      ".single-range-hidden-input"
    );
    const inputInfo = singleRange.querySelector(".single-range-info");
    const startValue = +inputsHidden.value;

    noUiSlider.create(slider, {
      start: [startValue],
      connect: "lower",
      margin: 10,
      step: step,
      range: {
        min: min,
        max: max,
      },
    });

    slider.noUiSlider.on("update", function (values, handle) {
      inputInfo.textContent = `${Math.round(values[handle])} ${unit}`;

      inputsHidden.value = Math.round(values[handle]);
    });
  }
};
singleRangeSlider();

const btnShowHideArr = document.querySelectorAll(".btn-show-hide");
const showHideContentArr = document.querySelectorAll(".show-hide-content");

showHideContentArr.forEach((content, i) => {
  const items = content.querySelectorAll("li");
  const maxVisibleItems = content.classList.contains("with-sectors") ? 8 : 5;

  items.forEach((item, index) => {
    if (index > maxVisibleItems - 1) {
      item.classList.add("is-hidden");
    }
  });

  if (items.length > maxVisibleItems) {
    const btnShowHide = btnShowHideArr[i];
    btnShowHide.classList.add("is-visible");

    btnShowHide.addEventListener("click", () => {
      btnShowHide.classList.toggle("active");
      items.forEach((item, index) => {
        if (index > maxVisibleItems - 1) {
          item.classList.toggle("is-hidden");
        }
      });
    });
  }
});

const contentSticky = document.getElementById("content-sticky");
const rightSidebar = document.getElementById("sidebar");
if (rightSidebar) {
  const sidebarBuffer = rightSidebar.querySelector(".sidebar__buffer");
  const sidebarInner = rightSidebar.querySelector(".sidebar__inner");
  // const headerHeight = +getRootStyle("--header-height").replace("px", "");
  const headerHeight = 0;

  // gap on top and bottom of sticky sidebar
  const gap = 30;

  let lastScrollTop = 0;

  // if flag true === scrolling down else scrolling up
  let flag = null;

  // if flagHeight true === sidebar is higher than viewport height
  let flagHeight =
    document.querySelector(".sidebar__inner").getBoundingClientRect().height >
    window.innerHeight - headerHeight;

  function calcHeightOfSidebar(flag) {
    const contentStickyTop = getPosition(contentSticky).y;
    const sidebarInnerTop = getPosition(sidebarInner).y;
    sidebarBuffer.style.height = `${sidebarInnerTop - contentStickyTop}px`;

    if (flag) {
      // scroll up
      sidebarInner.style.top = "";
      sidebarInner.style.bottom = `${
        -headerHeight -
        gap +
        window.innerHeight -
        sidebarInner.getBoundingClientRect().height
      }px`;
    } else {
      // scroll down
      sidebarInner.style.bottom = "";
      sidebarInner.style.top = `${
        Math.min(
          window.innerHeight - sidebarInner.getBoundingClientRect().height,
          headerHeight
        ) - gap
      }px`;
    }
    flagHeight =
      sidebarInner.getBoundingClientRect().height >
      window.innerHeight - headerHeight;
  }

  function resetSticky() {
    sidebarInner.style.bottom = "";
    sidebarInner.style.top = `${headerHeight + gap}px`;
    sidebarBuffer.style.height = "0";
  }

  if (!flagHeight) {
    resetSticky();
  } else {
    calcHeightOfSidebar(false);
  }

  window.addEventListener("scroll", function () {
    let st = window.pageYOffset || document.documentElement.scrollTop;

    if (!flagHeight) {
      resetSticky();
    }

    if (st > lastScrollTop && !flag) {
      // scroll down
      calcHeightOfSidebar(flag);
      flag = true;
    } else if (st < lastScrollTop && flag) {
      // scroll up
      calcHeightOfSidebar(flag);
      flag = false;
    }
    lastScrollTop = st <= 0 ? 0 : st;
  });

  window.addEventListener("resize", function (e) {
    if (!flagHeight) {
      resetSticky();
    }

    calcHeightOfSidebar(true);
    calcHeightOfSidebar(false);
  });
}

if (document.getElementsByClassName("btn-filters")[0]) {
  const btnFilters = document.querySelectorAll(".btn-filters");
  const sidebar = document.querySelector(".sidebar");
  const jobsList = document.querySelector(".jobs-list");
  const jobsSearchContainer = document.querySelector(
    ".jobs-search-section__wrap"
  );
  /*const paginationSectionJobs = document.querySelector(
    ".result-section.jobs-page"
  );*/

  btnFilters.forEach((btn, i) => {
    btn.addEventListener("click", () => {
      btnFilters.forEach((button) => {
        button.classList.toggle("active");
      });
      sidebar.classList.toggle("active");
      jobsList.classList.toggle("active");
      jobsSearchContainer.classList.toggle("active");
      /*paginationSectionJobs.classList.toggle("active");*/
    });
  });

  const sidebarSubmitBtn = document.querySelector(".sidebar__submit-btn");

  if (sidebarSubmitBtn) {
    sidebarSubmitBtn.addEventListener("click", () => {
      scrollToEl("#content-sticky|300");
      btnFilters.forEach((button) => {
        button.classList.remove("active");
      });
      sidebar.classList.remove("active");
      jobsList.classList.remove("active");
      jobsSearchContainer.classList.remove("active");
    });
  }

  /*const container = document.querySelector(".btn-text-filter");

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Контейнер достиг нижней границы
          // Выполните необходимые действия
          console.log(123);
        }

        console.log(222);
      });
    },
    {
      threshold: 0,
      rootMargin: "0% 0% -10% 0%",
    }
  );

  observer.observe(container);*/
}

if (document.querySelector(".areas-section__slider")) {
  const areasAccordionItemsArr = document.querySelectorAll(
    ".areas-section__item"
  );
  areasAccordionItemsArr.forEach((item, i) => {
    item.addEventListener("click", () => {
      item.classList.toggle("active");
    });
  });
}

/*var elements = document.querySelectorAll(".force-sticky");
Stickyfill.forceSticky();
Stickyfill.add(elements);*/

var scroll = new SmoothScroll('a[href*="#"]');
